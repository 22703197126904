import UtilsCookies from 'CommonUtils/cookies';

const _MARKETPLACE_STORE_ID = '135';

function getDatalayerProductReferenceId(color, selectedProviderOfferId) {
  if (selectedProviderOfferId) {
    return selectedProviderOfferId;
  }
  if (color && color.offer_id) {
    return color.offer_id;
  }
  if (color && color.common_sku) {
    return color.common_sku;
  }
  return undefined;
}

function getProductReferenceId(selectedSku, currentSize) {
  if (currentSize.selectedProviderOfferId) {
    return currentSize.selectedProviderOfferId;
  }
  if (selectedSku.offer_id) {
    return selectedSku.offer_id;
  }
  if (currentSize.sku) {
    return currentSize.sku;
  }
  return undefined;
}

function getVariantById(colors, id) {
  if (!colors || !colors.length || !id) return null;
  for (let i = 0; i < colors.length; i++) {
    const variants = colors[i].variants;
    for (let j = 0; j < variants.length; j++) {
      const variant = variants[j];
      if (variant.id.trim() === id.trim()) {
        return { color: colors[i], variant };
      }
    }
  }
  return null;
}

function mapBooleanRelatedServices(relatedServices) {
  const mappedRelatedServices = {};
  relatedServices &&
    relatedServices['booleans']?.forEach((service) => {
      mappedRelatedServices[service.name] = service.selectedOption;
    });
  return mappedRelatedServices;
}

function getSelectedInstallationRelatedServices(relatedServices) {
  return (
    relatedServices &&    
    relatedServices['installations']
      ?.filter((relatedService) => {
        return relatedService.selectedOption;
      })
      .concat(relatedServices['mandatoryInstallations'])
  );
}

function createInstallationRelationServicesLineItems(installationService, productInCart, productReference) {
  const skuData = installationService?.product?._single_sku;
  const unitsInCart = getInstallationServiceUnitsInCart(skuData?.reference, productInCart);
  return {
    reference: skuData.reference,
    gtin: skuData.gtin,
    // url: installationService?.product?._uri,
    marketplace: Boolean(skuData.marketplace),
    qualifier: productReference,
    store_id: skuData.store_id,
    units: unitsInCart ? unitsInCart + 1 : 1,
  };
}

function addInstallationRelatedServiceToCart(relatedServices, lineItems, productInCart, currentProduct) {
  const selectedInstallationServices = getSelectedInstallationRelatedServices(relatedServices);
  if (selectedInstallationServices?.length) {
    selectedInstallationServices.forEach((installationService) => {
      lineItems.push(
        createInstallationRelationServicesLineItems(installationService, productInCart, currentProduct.reference)
      );
    });
    if (currentProduct.data_layer) {
      currentProduct.data_layer.hasCostService = true;
    }
    currentProduct.hasCostService = true;
  }
}

function getCostProductInCart(mainCurrentReference, costProduct, cartData) {
  if (!cartData?.items?.length) return;

  const currentFind = ({ reference, custom_items }) => mainCurrentReference && reference.trim() === mainCurrentReference?.trim() && !custom_items;
  const costFind = ({ reference, custom_items }) => reference.trim() === costProduct.reference.trim() && !custom_items;
  
  const costProductInCart = cartData.items.find(costFind);
  if (costProductInCart) return costProductInCart;

  const productInCart = cartData.items.find(currentFind);
  const costProductOnProductInCart = productInCart?.costs?.find(costFind);

  return costProductOnProductInCart;
}

function getProductInCart(currentProduct, cartData) {
  if (!cartData) {
    return {};
  }
  const productInCart =
    cartData.items.length &&
    cartData.items.find(({ reference, custom_items }) => {
      return reference.trim() === currentProduct.reference.trim() && !custom_items;
    });

  return productInCart;
}

function getInstallationServiceUnitsInCart(installationServiceReference, productInCart) {
  if (productInCart?.costs?.length) {
    const installationServiceInCart = productInCart.costs.find((cost) => {
      return cost.reference?.trim() === installationServiceReference?.trim();
    });

    return installationServiceInCart.units ?? 0;
  }
  return 0;
}

function formatDate(date) {
  return date && new Date(date).toISOString().split('T')[0];
}

function mapShippingDates(stock, shippingInfo) {
  const result = {
    delivery_date_timestamp: formatDate(new Date()),
  };
  if (shippingInfo.launching_date) {
    return {
      ...result,
      delivery_date: formatDate(shippingInfo.launching_date),
      delivery_date_slot: "false",
    }
  }
  if (stock === 'V48') {
    return {
      ...result,
      delivery_date: formatDate(shippingInfo.delivery_start_date_from_with_stock),
      delivery_date_slot: "false",
    }
  }
  if (stock === 'V05') {
    return {
      ...result,
      delivery_date: formatDate(shippingInfo.delivery_start_date_from_with_stock),
      delivery_date_exact_time: "false",
    }
  }
  if (shippingInfo.availability_date) {
    return {
      ...result,
      delivery_date: formatDate(shippingInfo.availability_date),
      delivery_date_slot: "false",
      delivery_date_exact_time: "false",
    }
  }
  if (shippingInfo.slot_initial_date_without_stock) {
    return {
      ...result,
      delivery_date_start: formatDate(shippingInfo.slot_initial_date_without_stock),
      delivery_date_end: formatDate(shippingInfo.delivery_start_date_from_without_stock),
      delivery_date_slot: "true",
    }
  }
  return {
    ...result,
    delivery_date: formatDate(shippingInfo.delivery_start_date_from_without_stock),
    delivery_date_exact_time: "false",
  }
}

function handleSeller(productId) {
  if (!productId) return;
  const VENDORS = {
    DIALOGA: {
      CUE: '01'
    }
  };
  const name      = 'expert_vr.' + productId;
  const vendorId  = UtilsCookies.getCookie(name);
  if (!vendorId) return;
  return {
    seller: vendorId,
    type_seler: VENDORS.DIALOGA.CUE,
  }
}

export default {
  formatCartObject: (
    product,
    currentSize,
    cartData,
    shipping_method = 'home_delivery', //TODO: SUELE SER EL ENVIO POR DEFECTO, VALORAR SI DEJARLO
    checkForExistingUnits = false,
    vendor = undefined,
    relatedServices,
    sofaConfiguratorStepsIds,
    stock = undefined,
    shippingInfo = undefined,
    school,
    isActiveSellerReport,
    thirdSize,
    isGiftCard = false,
    activeSku = null
  ) => {
    const {
      id,
      _datalayer,
      _my_colors,
      _single_sku,
      _status,
      _uri,
      title,
      brand,
      categories,
      insurance_type,
      provider,
      hierarchy,
      group_by,
      store_id,
      units,
      product_store_datalayer,
    } = product;

    let seller, origin;

    const selectedSku = currentSize;
    const { color, variant } = getVariantById(_my_colors, currentSize.sku);

    const {
      gtin,
      flags,
      sale_price,
      price,
      discount,
      isMarketplace,
      status,
      seller: variantSeller,
      origin: variantOrigin,
      providers
    } = variant;

    seller = variantSeller;
    origin = variantOrigin;

    const selectedProvider = providers?.find(({ stockReference }) => stockReference === activeSku);
    
    if (isMarketplace && selectedProvider) {
      // Send data of marketplace provider
      const { offer, provider_id } = selectedProvider;
      const { offer_id } = offer;

      seller = provider_id;
      origin = offer_id;
    }

    if (product_store_datalayer) vendor = toRaw(product_store_datalayer).vendor;

    let delivery_options;
    let from_searcher;
    let referenceId;
    if (color) {
      referenceId = getDatalayerProductReferenceId(color, currentSize.selectedProviderOfferId);
    }

    if (selectedSku) {
      delivery_options = selectedSku.delivery_options;
      from_searcher = selectedSku.from_searcher;
    }

    const BADGES = {
      express: delivery_options?.express,
      new: flags?.new,
      exclusive: flags?.internetExclusive,
      eci_exclusive: flags?.eci_exclusive,
      coming_soon: flags?.coming_soon,
      express_delivery: delivery_options?.express_delivery,
    };

    const PRICE = {
      ...(sale_price && { o_price: Number(price) }),
      f_price: Number(sale_price ? sale_price : price),
      discount: Number(sale_price ? Math.round(price - sale_price) : 0),
      discount_percent: Number(discount ? discount : 0),
      currency: 'EUR',
    };

    const pageType = window?.dataLayer?.at(0)?.page?.type;
    const data_layer = {
      id: referenceId,
      gtin: _single_sku ? _single_sku.gtin : gtin,
      status: status || _status,
      variant: variant.id,
      store_id: _single_sku ? _single_sku.store_id : store_id,
      name: title,
      brand: brand?.name ?? '',
      badges: BADGES,
      price: PRICE,
      media: { count: 1 },
      category: _datalayer?.[0]?.product?.category || categories?.map((category) => category.name) || [],
      code_a: id,
      eci_provider: provider?.name ?? '',
      hierarchy: hierarchy ?? _datalayer?.[0]?.product?.hierarchy,
      group_by: group_by,
      quantity: selectedSku.selectedUnits ?? units ?? 1,
      customization: {
        available: selectedSku.productCustomization ? 1 : 0,
        add_to_cart: 0,
      },
      ...(pageType && { page_type: pageType }),
    };

    if(thirdSize) {      
      data_layer.third_driver = thirdSize;
    }
    
    const schoolPayload = school ? { school_id : school } : {};
    if (vendor) {
      data_layer.vendor = vendor;
    }

    if (sofaConfiguratorStepsIds?.length) {
      data_layer.motion_childs = String(sofaConfiguratorStepsIds);
      data_layer.third_driver = 'sofa configurator';
    }

    const sellerReport = isActiveSellerReport && handleSeller(id)
    
    const ADD_PRODUCT_TO_CART = {
      line_items: [
        {
          reference: getProductReferenceId(selectedSku, currentSize),
          gtin: _single_sku ? _single_sku.gtin : gtin,
          marketplace: isMarketplace,
          url: _uri,
          store_id,
          from_searcher,
          shipping_method,
          data_layer,
          units: selectedSku.selectedUnits ?? units ?? 1,
          ...mapBooleanRelatedServices(relatedServices),
          ...(insurance_type && { insurance_type }),
          ...(stock && shippingInfo && mapShippingDates(stock, shippingInfo)),
          ...schoolPayload,
          seller,
          origin,
          ...sellerReport,
        },
      ],
    };

    const currentProduct = ADD_PRODUCT_TO_CART.line_items[0];
    const productInCart = getProductInCart(currentProduct, cartData);
    if (!checkForExistingUnits && productInCart) {
      currentProduct.units = productInCart.units + (selectedSku.selectedUnits ?? currentProduct.units);
      currentProduct.data_layer.quantity = selectedSku.selectedUnits ?? currentProduct.units;
    }

    addInstallationRelatedServiceToCart(relatedServices, ADD_PRODUCT_TO_CART.line_items, productInCart, currentProduct);

    if (isGiftCard) {
      ADD_PRODUCT_TO_CART.line_items[0].gift_card_amount = currentSize.price;
      ADD_PRODUCT_TO_CART.line_items[0].reference = ADD_PRODUCT_TO_CART.line_items[0].reference?.split('_')?.[0];
      ADD_PRODUCT_TO_CART.line_items[0].data_layer.price = {
        f_price: Number(currentSize.price),
        discount: 0,
        discount_percent: 0,
        currency: 'EUR',
      };
      ADD_PRODUCT_TO_CART.line_items[0].data_layer.variant = ADD_PRODUCT_TO_CART.line_items[0].data_layer?.variant?.split('_')?.[0];
    }

    return ADD_PRODUCT_TO_CART;
  },
  formatCostPromoObject: (product, variant, cartData, customParams = {}) => {
    const { id, store_id, url } = product;
    const { gtin, reference } = variant;
    const {
      units = 1,
      mainCurrentReference = null,
      allowAddingUnits = true,
    } = customParams;


    const lineItem = {
      id,
      store_id,
      url,
      gtin,
      reference,
      units
    };
    
    const { units: costProductInCartUnits } = getCostProductInCart(mainCurrentReference, variant, cartData) ?? {};
    if (allowAddingUnits && costProductInCartUnits) lineItem.units += costProductInCartUnits;
    
    const line_items = [lineItem];
    return { line_items };
    
  },
  formatSofaConfigurationProducts(sofaConfiguratorData, storeId, url, mainProductRelatedServices) {
    const { steps: sofaConfiguratorSteps, variant: mainProductVariant } = sofaConfiguratorData;
    return sofaConfiguratorSteps.map((step) => {
      const currentStepsRelatedServices = this.getSofaConfiguratorStepRelatedServices(step, mainProductRelatedServices);
      return {
        cuantity: 1,
        data_layer: {
          conf_type: 'motiondisplay',
          reference: mainProductVariant,
          rel: step.id,
          type: 'child',
        },
        'first-available-sku': step.variant,
        id: step.id,
        reference: step.variant,
        shipping_method: 'home_delivery',
        store_id: storeId,
        units: 1,
        url: url,
        ...currentStepsRelatedServices,
      };
    });
  },

  getSofaConfiguratorStepRelatedServices(currentStep, mainProductRelatedServices) {
    const currentStepsRelatedServices = currentStep.signalServices
      ?.filter((signalService) => signalService.type === 'BOOLEAN')
      .map((filteredSignalService) => {
        return filteredSignalService.name;
      });

    if (currentStepsRelatedServices?.length) {
      const presentRelatedServices = {
        booleans: mainProductRelatedServices.booleans?.filter((booleanService) =>
          currentStepsRelatedServices.includes(booleanService.name)
        ),
      };
      return mapBooleanRelatedServices(presentRelatedServices);
    }
    return undefined;
  },

  mapperUnitsObject(item, ACTION, TYPE_ACTION) {
    let resultActionOperation;

    const { id, units, reference, store_id } = item;

    if (ACTION === TYPE_ACTION.PLUS) resultActionOperation = units + 1;

    if (ACTION === TYPE_ACTION.MINUS && units === 1) return;

    if (ACTION === TYPE_ACTION.MINUS && units > 1) resultActionOperation = units - 1;

    const UPDATE_PRODUCT_TO_CART = {
      line_items: [
        {
          id: id,
          reference: reference,
          units: resultActionOperation,
          store_id: store_id,
          data_layer: { ...item.data_layer, quantity: resultActionOperation },
          marketplace: store_id === _MARKETPLACE_STORE_ID,
        },
      ],
    };

    return UPDATE_PRODUCT_TO_CART;
  },
  isInsurance(item) {
    return item.insurance_type === 'insurance';
  },
  canAddInsuranceItem(items, insurableRef) {
    if (!Array.isArray(items)) {
      return false;
    }

    let insurableProductInCart;
    let insuranceInCart;
    items.forEach((item) => {
      if (item.reference === insurableRef) insurableProductInCart = item;
      if (item.insurable_ref && item.insurable_ref === insurableRef) insuranceInCart = item;
    });

    if (insurableProductInCart) {
      return insuranceInCart ? insurableProductInCart.units > insuranceInCart.units : true;
    }
    return false;
  },
  canAddInsuranceUnits(items, insurableRef) {
    if (!Array.isArray(items)) return true;

    const insurableProductInCart = items.find(({ reference }) => reference === insurableRef);
    const hasInsuranceInCart = items.find(({ insurable_ref }) => insurable_ref && insurable_ref === insurableRef);

    if (insurableProductInCart?.financial_info) return !hasInsuranceInCart;
    return true;
  },
  mapRelatedServices(relatedServices) {
    return mapBooleanRelatedServices(relatedServices);
  },
  addInstallationRelatedServiceToCart: addInstallationRelatedServiceToCart,
  getProductInCart: getProductInCart,
  getVariantById: getVariantById,
  mapShippingDates: mapShippingDates,
};
