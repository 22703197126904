import { mapActions, mapGetters } from 'vuex';
import NavigatorDetect from 'CommonMixins/navigatorDetect';
import AppEvents from 'CommonUtils/appEvents';
import CART_ACTION_TYPES from 'CommonUtils/cartActionTypes';
import UtilsCart from 'CommonUtils/formatCartObject.js';
import BTN_PLUS_DISABLED_TYPES from 'CommonUtils/btnPlusDisabledTypes';

const cartActions = {
  mixins: [NavigatorDetect],
  computed: {
    ...mapGetters('cart', {
      isLoadingCart: 'getLoadingStatus',
      getFullCartData: 'getFullCartData',
      getTotalItems: 'getTotalItems',
      getApiResponse: 'getApiResponse',
      isEciCardError: 'isEciCardError',
      hasCouponError: 'hasCouponError',
      isLogged: 'isLogged',
      getUser: 'getUser',
      getGuestEmail: 'getGuestEmail'
    }),
    ...mapGetters('page', {
      isApp: 'isApp',
      internals: 'getInternals',
    })
  },
  methods: {
    ...mapActions('cart', {
      getCart: 'getCart',
      deleteItemByCart: 'deleteItemByCart',
      postCart: 'postCart',
      postCouponCode: 'postCouponCode',
      putCart: 'putCart',
      showExpiredCartError: 'showExpiredCartError',
      processToCheckout: 'processToCheckout',
      postCartVpr: 'postCartVpr'
    }),
    hasExpiredCartQuery() {
      const expiredQueryValue = this.internals?.req?.query?.expired;
      const hasEmptyBasket = this.getTotalItems < 1;
      const isExpiredQueryValid = expiredQueryValue === 'true';

      if (hasEmptyBasket && isExpiredQueryValid) {
        this.showExpiredCartError({
          status: true,
          msg: this.$i18n.t('checkout.errors.EXPIRED_CART')
        });
      }
    },
    sendPutCart(options) {
      this.putCart(options);
    },
    emptyCart() {
      this.sendPutCart({});
      if (this.isApp) AppEvents.messageEmptyCart();
    },
    sendGiftTicketCart(options) {
      this.postCart(options);
    },
    async sendGetCart() {
      const isModal = this.internals?.req?.query?.modal
      if(isModal) return;
      await this.getCart();
      this.hasExpiredCartQuery();
      if (!this.isApp) return;

      AppEvents.messageSessionCartUpdate();
      AppEvents.messageSendUnits(this.getTotalItems);
    },
    async sendUpdateItemCart(
      payload,
      is_from_checkout,
      successAction = CART_ACTION_TYPES.BASKET,
      vprPostalCode = undefined
    ) {
      const isFromCheckout = Boolean(is_from_checkout);
      const cartItems = {
        ...(payload.line_items?.[0] && {line_items: payload.line_items}),
        ...(payload.free_gift_skus?.[0] && {free_gift_skus: payload.free_gift_skus}),
        ...(payload.insurance_items?.[0] && {insurance_items: payload.insurance_items}),
      };
      if (this.isApp) {
        if (!this.isLogged) return AppEvents.messageNeedLogin();
        const result = await this.sendPostToCart(
          cartItems,
          isFromCheckout,
          successAction,
          vprPostalCode
        );
        await this.sendPostCartToApp('add_item');
        return result;
      }
      return this.sendPostToCart(
        cartItems,
        isFromCheckout,
        successAction,
        vprPostalCode
      );
    },
    addToCartAMV(data) {
      Android.addToCartUnits(data.reference, data.gtin, data.url, data.units);
    },
    async handleBuyLaterAddToCart(items) {
      if (this.isApp) {
        if (!this.isLogged) return AppEvents.messageNeedLogin();
        await this.postCart(items);
        return this.sendPostCartToApp('add_item');
      }
      await this.postCart(items);

    },
    async sendDeleteItemCart(line_items, is_from_checkout) {
      if (this.isApp) {
        if (!this.isLogged) return AppEvents.messageNeedLogin();
        await this.deleteItemByCart({
          line_items: line_items,
          is_from_checkout: is_from_checkout
        });
        return this.sendPostCartToApp('remove_item');
      }
      await this.deleteItemByCart({
        line_items: line_items,
        is_from_checkout: is_from_checkout
      });
    },
    sendPostCartToApp(type) {
      const fullResponse = this.getApiResponse;
      if (fullResponse.error) return;

      const data = fullResponse.response;
      data[type] = true;
      AppEvents.messageUpdateCart(data);
      AppEvents.messageSendUnits(this.getTotalItems);
    },
    hasReachedMaxInsuranceUnits(item) {
      return UtilsCart.isInsurance(item) && !UtilsCart.canAddInsuranceItem(this.getFullCartData.items, item.insurable_ref);
    },
    hasReachedMaxUnits(item) {
      return item.description === BTN_PLUS_DISABLED_TYPES.MAX_UNITS || this.hasReachedMaxInsuranceUnits(item);
    },
    async sendPostToCart(cartItems, is_from_checkout, successAction, vprPostalCode) {
      if (vprPostalCode) {
        return this.postCartVpr({
          ...cartItems,
          is_from_checkout,
          successAction,
          vprPostalCode,
        });
      }
      return this.postCart({
        ...cartItems,
        is_from_checkout,
        successAction
      });
    }
  }
};

export default cartActions;
